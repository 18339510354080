import React, {useEffect, useState, useRef} from 'react';
import Header from './components/Header';
import Chart from './components/Chart';
import TickerListView from './components/TickerListView';
import './App.css';
import * as d3 from 'd3';
import { intervalInMillis, formatPrice } from './utils.js'; // Adjust the path according to your project structure
import GridLayout, {WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { FaGripVertical } from 'react-icons/fa'; // Import an icon for drag handle

/**
 *
 * TODO [FEATURE] Add charts by clicking on tickers tickerListView
 * TODO [BUG] Delete takes too long. Just remove line.
 * TODO [DATA] Skew data on daily timeframe
 *
 */

// Spinner CSS
const spinnerStyle = {
    display: 'inline-block',
    width: '50px',
    height: '50px',
    border: '3px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '50%',
    borderTopColor: '#007bff',
    animation: 'spin 0.6s ease-in-out infinite',
};

// Spinner Animation
const keyframes = `
@keyframes spin {
  to { transform: rotate(360deg); }
}
`;

const ResponsiveGridLayout = WidthProvider(GridLayout);

function App() {
    const [data, setData] = useState([]);
    const [currentPrice, setCurrentPrice] = useState(null);
    const [titles, setTitles] = useState(['BTCUSDT · 1h · Binance-Futures · 57449;R;#1f77b4']);
    const [currentTicker, setCurrentTicker] = useState(['BTCUSDT, Binance-Futures']);
    const [dateRangeData, setDateRangeData] = useState([]);
    
    const [tickers, setTickers] = useState([]); // Dynamic tickers
    const [timeframe, setTimeframe] = useState('1h'); // Track the current timeframe

    const [width, setWidth] = useState(window.innerWidth); // State to hold screen width
    const [height, setHeight] = useState(window.innerHeight); // State to hold screen Height
    const [chartHeight, setChartHeight] = useState(400); // Initial height for the top chart box
    const [bottomHeight, setBottomHeight] = useState(300); // Initial height for the bottom box
    const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5000'; // Fallback to localhost for development

    const [layout, setLayout] = useState([
        { i: 'box1', x: 0, y: 0, w: 12, h: 20, minW: 2, minH: 4, resizeHandles: ['s'] }, // Resizable from the bottom, full width
        { i: 'box2', x: 0, y: 6, w: 12, h: 10, minW: 2, minH: 2, resizeHandles: ['s'] }, // Below the chart
    ]);

    const fetchFlagRef = useRef(false); // Use useRef to manage fetchFlag

    // Fetch the list of tickers from the server
    const fetchTickers = async () => {
        try {
            const response = await fetch(`${API_URL}/api/get_tickers`); // Update with correct API endpoint
            const result = await response.json();
            setTickers(result); // Set tickers state with the fetched result
            // setCurrentTicker([result[0]]); // Set the first ticker as the initial current ticker
        } catch (error) {
            console.error('Error fetching tickers:', error);
        }
    };

    useEffect(() => {
        console.log('🪝 INIT HOOK : Fetching ticker list');
        fetchTickers(); // Fetch ticker list from the server
    }, []); // Empty dependency array means this runs once when the component mounts

    const fetchTickerData = async (ticker, startTime, endTime) => {
        console.log('📞 Fetching data for', ticker);
        const numPoints = 700; // Define the target number of data points for high fidelity

        const [symbol, exchange] = ticker.split(', ');
        const [shortSymbol, tenor, indicator] = symbol.split(' ');
        // if endtime is not provided, set it to the current time
        if (!endTime) {
            endTime = Date.now();
        }
        const endDateTime = new Date(endTime).toISOString();
        const now = Date.now();
        // if starttime is not provided, calculate the lookback period based on the number of points
        if (!startTime) {
            startTime = now - numPoints * intervalInMillis(timeframe);
        }
        const startDateTime = new Date(startTime).toISOString();

        // Print the start and end time in log in one line
        console.log(' ⌙ Start:', new Date(startTime), 'End:', new Date(endTime));

        // Get the user's local timezone offset in milliseconds
        const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

        let url;

        try {
            if (exchange === 'Binance-Futures') {
                // Live updates
                // url = `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&startTime=${startTime}&endTime=${endTime}`;
                url = `${API_URL}/api/get_ohlcv?exchange=binanceusdm&symbol=${symbol}&interval=${timeframe}&start_date=${startDateTime}&end_date=${endDateTime}`;
                console.log('URL:', url);
                const response = await fetch(url);
                const result = await response.json();
                const formattedData = result.map(([time, open, high, low, close, volume]) => ({
                    date: new Date(time - timezoneOffset),
                    price: +close,
                }));

                // console.log('Formatted data:', formattedData);
                return formattedData;
            } else if (exchange.startsWith('Deribit')) {
                // Example URL for Deribit-Indicators, adjust according to your API needs
                url = `https://api.deribit.com/api/v2/public/get_index_price?ticker=${symbol}`;
                const response = await fetch(url);
                const result = await response.json();
                // Assuming result contains a 'result' array of {time, price} objects
                const formattedData = result.result.map(dataPoint => ({
                    date: new Date(dataPoint.time - timezoneOffset),
                    price: +dataPoint.price,
                }));

                return formattedData;
            } else if (exchange.startsWith('Indicator')) {
                const resolution = '1%20hours';
                url = `${API_URL}/api/get_skewdata?coin=${shortSymbol}&start_date=${startDateTime}`;
                console.log('URL:', url);
                const response = await fetch(url);
                const result = await response.json();

                // Define a map to select the correct price index based on tenor
                const tenorMap = {
                    '1w': 1,
                    '1m': 2,
                    '3m': 3,
                    '6m': 4
                };

                const formattedData = result.map(dataPoint => {
                    const date = new Date(dataPoint[0] * 1000 - timezoneOffset);
                    let price = +dataPoint[tenorMap[tenor]];

                    // Apply specific calculations for BTC and ETH hansolar indicators
                    if (symbol === 'BTC Skew Indicator') {
                        // Average the 1m and 6m skews and add 0.0463
                        const skew1m = +dataPoint[tenorMap['1m']];
                        const skew6m = +dataPoint[tenorMap['6m']];
                        price = (skew1m + skew6m) / 2 + 0.0144;
                    } else if (symbol === 'ETH Skew Indicator') {
                        // Average the 1m and 6m skews and add 0.0144
                        const skew1m = +dataPoint[tenorMap['1m']];
                        const skew6m = +dataPoint[tenorMap['6m']];
                        price = (skew1m + skew6m) / 2 + 0.0463;
                    }

                    return { date, price };
                });

                return formattedData;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // ✨ Fetch data for all tickers and set axis side
    const updateData = async (ticker, startTime, endTime, fetchType) => {
        /**
         * Fetch data for all tickers and update the state
         * @param {Array} ticker - Array of tickers to fetch data for
         * @param {Number} startTime - Start time for the data fetch
         * @param {Number} endTime - End time for the data fetch
         * @param {String} fetchType - Type of fetch (tickerChange(delete and add lines), fetchMoreData, timeframe)
         */
        try {
            console.log('[updateData] ✨ Updating data for', currentTicker);
            console.log(' ⌙ fetchType : ', fetchType);
            // Fetch data for all tickers and save to array
            let newDataSets = await Promise.all(
                currentTicker.map(ticker => fetchTickerData(ticker, startTime, new Date().getTime()))//endTime))
            );
            // If this is the first load and data is empty, initialize with the fetched data
            let updatedDataSets = [];
            if (data.length === 0) {
                console.log('[updateData] Initializing data sets with new data.');
                updatedDataSets = newDataSets;  // Initialize with new data
                // 🪝 Run hook to update data -> re-renders chart
                setData(newDataSets);
            } else {
                console.log('[updateData] data length now for index 0', data[0].length);
                // If data is already present, append new data points to the existing dataset
                updatedDataSets = newDataSets.map((newData, index) => {
                    const existingData = data[index];

                    // Add detailed logs for newData and existingData
                    // console.log(`[updateData] Processing index ${index}`);
                    // console.log('[updateData] newData:', newData);
                    // console.log('[updateData] existingData:', existingData);
                    let combinedData = [];

                    // If the fetch type is tickerChange, replace the existing data with the new data
                    if (fetchType === "tickerChange") {
                        combinedData = [...newData];
                    } else if (fetchType === "fetchMoreData") {
                        // If data is already present, append new data points to the existing dataset
                        if (existingData !== undefined) {
                            // Ensure the new data points have proper Date objects for comparison
                            const uniqueNewData = newData.filter(newPoint => {
                                const newPointDate = new Date(newPoint.date); // Ensure newPoint.date is a Date object
                                return !existingData.some(existingPoint =>
                                    existingPoint.date.getTime() === newPointDate.getTime()
                                );
                            });
                            // Append new data to the existing dataset and ensure it's sorted by date
                            combinedData = [...existingData, ...uniqueNewData];
                        }
                    } else if (fetchType === "timeframe") {
                        combinedData = [...newData];
                    }

                    // Sort by the date to ensure chronological order
                    return combinedData.sort((a, b) => a.date - b.date);
                });
                console.log('[updateData] data length for updatedDataSets[0]:', updatedDataSets[0].length);
                console.log('[updateData] datasets are updated', updatedDataSets);
                // 🪝 Run hook to update data -> re-renders chart
                setData(updatedDataSets);
            }

            console.log('[updateData] before formatPrice');

            // Update current price
            const prices = updatedDataSets.map(dataSet => formatPrice(dataSet[dataSet.length - 1]?.price || null));
            setCurrentPrice(prices);

            // Update titles with axis side
            const updatedTitlesResult = currentTicker.map((ticker, index) => {
                let axisSide = 'R';
                let lineColor = d3.schemeCategory10[index % 10];

                // Set line color based on title changes
                if (titles !== null && titles[index] !== undefined) {
                    // Updates titles
                    axisSide = titles[index].split(';')[1];
                    // Set line color
                    if (!!titles[index].split(';')[2]) {
                        lineColor = titles[index].split(';')[2];
                    } else {
                        lineColor = d3.schemeCategory10[index % 10];
                    }
                } else if (titles !== null && titles[index] === undefined) {
                    console.log('[updateData] Adding title for new line. No title for index ', index);
                    titles[0].split(';')[1] === 'R' ? axisSide = 'L' : axisSide = 'R';
                    lineColor = d3.schemeCategory10[index % 10];
                }

                // if titles exchange is indicator, add 25% opacity
                if (ticker.split(', ')[1].includes('Indicator')) {
                    lineColor = d3.color(lineColor).copy({opacity: 0.70}).formatRgb();
                }

                // Format price and return the updated title string
                return `${ticker.split(', ')[0]} · ${timeframe} · ${ticker.split(', ')[1]} · ${prices[index]};${axisSide};${lineColor}`;
            })
            // 🪝Run hook to update titles -> re-renders chart
            setTitles(updatedTitlesResult);

            // Fetch flag over
            fetchFlagRef.current = false;
            console.log('[updateData] 🚨 Update Fetch Flag set to FALSE');
        } catch (error) {
            console.error('[updateData] Error fetching data:', error);
        }
    };
     // Function to update parent data
  const handleDateRange = (dataFromChild) => {
    setDateRangeData(dataFromChild);
  };

    // Fetch data when current ticker changes
    useEffect(() => {
        // console.log('🪝 Current ticker initiated/updated:', currentTicker);
        if(dateRangeData.length > 0){
            updateData(currentTicker, dateRangeData[0].getTime(), dateRangeData[1].getTime(), "tickerChange");
        }
        else{
        updateData(currentTicker, false, false, "tickerChange");
        }
    }, [currentTicker]);

    // Logic to update layout height based on window size
    const updateLayoutHeight = () => {
        const windowHeight = window.innerHeight;
        const headerHeight = document.querySelector('header')?.offsetHeight || 0;
        const box1Height = 20 * 20; // Assuming 20 units for box1 height, adjust based on your rowHeight

        // Calculate the remaining space for box2 dynamically
        const remainingHeight = windowHeight - headerHeight - box1Height;
        const box2HeightInRows = Math.floor(remainingHeight / 20);

        setLayout(prevLayout =>
            prevLayout.map(item =>
                item.i === 'box2'
                    ? { ...item, h: Math.max(box2HeightInRows, item.minH) } // Ensure box2 has at least the minimum height
                    : item
            )
        );
    };

    useEffect(() => {
        const timeoutId = setTimeout(updateLayoutHeight, 100);

        // Update layout on window resize
        window.addEventListener('resize', updateLayoutHeight);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', updateLayoutHeight);
        };    }, []);


    return (
        <div className="App">
            <Header />
            <div className="container">
                {data.length > 0 ? (
                    <>
                        <ResponsiveGridLayout
                            className="layout"
                            layout={layout}
                            cols={12}
                            rowHeight={20}  // Adjust row height to fit your needs
                            width={width}
                            draggableHandle=".drag-handle" // Set draggable handle
                            margin={[0, 0]} // No space between the boxes
                        >
                            {/* Box for the chart */}
                            <div key="box1" style={{ background: 'none', minHeight: '40px' }}>
                                <div style={{ position: 'absolute', top: '0px', right: '4px', zIndex: 10, }} className="drag-handle">
                                    <FaGripVertical size={12} style={{ cursor: 'move', color: '#bdbdbd'}} />
                                </div>
                                <div key="chart1" style={{ width: '100%', height: '100%' }}>
                                    <Chart
                                        currentTicker={currentTicker}
                                        data={data}
                                        titles={titles}
                                        tickers={tickers}
                                        setTitles={setTitles}
                                        fetchTickerData={fetchTickerData}
                                        setCurrentTicker={setCurrentTicker}
                                        timeframe={timeframe}
                                        setTimeframe={setTimeframe}
                                        updateData={updateData}
                                        fetchFlagRef={fetchFlagRef}
                                        handleDateRange={handleDateRange}
                                    />
                                </div>
                            </div>

                            {/* Box for the TickerListView */}
                            <div key="box2" style={{ background: 'none', margin: '0px 0px 0px 0px' }}>
                                <div style={{ position: 'absolute', top: '3px', right: '4px' }} className="drag-handle">
                                    <FaGripVertical size={12} style={{ cursor: 'move', color: '#bdbdbd' }} />
                                </div>
                                <TickerListView
                                    tickers={tickers}
                                    currentTicker={currentTicker}
                                    setCurrentTicker={setCurrentTicker}
                                >
                                </TickerListView>
                            </div>
                        </ResponsiveGridLayout>
                    </>
                ) : (
                    <div className="loading-container">
                        <style>{keyframes}</style>
                        <div style={spinnerStyle}></div>
                        <p>Loading data...</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default App;
