import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useResizeColumns, useFlexLayout } from "react-table";

// Fetch real-time prices from Binance API
const fetchPrices = async () => {
    const response = await fetch("https://fapi.binance.com/fapi/v1/ticker/24hr");
    const data = await response.json();
    return data;
};

// Helper function to parse the ticker symbol and exchange
const parseTicker = (ticker) => {
    const [symbol, exchange] = ticker.split(", ");
    const cleanedSymbol = symbol.replace("USDT", "");
    return { symbol: cleanedSymbol, exchange };
};

/**
 * TickerListView component
 *
 * TODO : Fixed width columns and horizontal scrolling
 * TODO : Get sorting to work
 *
 * @param tickers
 * @param currentTicker
 * @param setCurrentTicker
 * @returns {JSX.Element}
 * @constructor
 */
const TickerListView = ({ tickers, currentTicker, setCurrentTicker }) => {
    const [tickerListData, setTickerListData] = useState([]);
    const [sortBy, setSortBy] = useState([{ id: 'symbol', desc: false }]); // Default sort
    const [columnWidths, setColumnWidths] = useState({
        symbol: 200, // Initial width for 'Symbol' column
        price: 150,  // Initial width for 'Price' column
        size: 100,   // Initial width for 'Size' column
        entry: 100,  // Initial width for 'Entry' column
        exchange: 150, // Initial width for 'Exchange' column
    });

    // Fetch Binance prices and update state
    useEffect(() => {
        const fetchTickersData = async () => {
            const priceData = await fetchPrices();
            const updatedTickers = tickers
                // .filter((ticker) => ticker.includes("Binance-Futures"))
                .map((ticker) => {
                    const { symbol, exchange } = parseTicker(ticker);
                    const priceInfo = priceData.find((price) => price.symbol === symbol + "USDT");
                    return {
                        symbol: symbol,
                        symbolLong: ticker,
                        price: priceInfo ? priceInfo.lastPrice : "- ",
                        size: " ",
                        entry: " ",
                        exchange: exchange,
                    };
                });
            setTickerListData(updatedTickers);
        };

        fetchTickersData();
        const intervalId = setInterval(fetchTickersData, 20000); // Update prices every 20 seconds
        return () => clearInterval(intervalId);
    }, [tickers]);

    // Editable cell logic
    const handleEdit = (rowIndex, columnId, value) => {
        const updatedData = [...tickerListData];
        updatedData[rowIndex][columnId] = value;
        setTickerListData(updatedData);
    };

    // Define columns for the table
    const columns = useMemo(() => [
        {
            Header: "Symbol",
            accessor: "symbol",
            width: columnWidths.symbol, // Use the width from state
            minWidth: 50,
            Cell: ({ value }) => <strong style={{ fontWeight: "bold" }}>{value}</strong>,
            canResize: true,
        },
        {
            Header: "Price",
            accessor: "price",
            width: columnWidths.price,
            minWidth: 50,
            disableSortBy: false,
            canResize: true,
        },
        {
            Header: "Size",
            accessor: "size",
            width: columnWidths.size,
            minWidth: 50,
            canResize: true,
            Cell: ({ row, value }) => (
                <input
                    type="text"
                    value={value}
                    onChange={(e) => handleEdit(row.index, "size", e.target.value)}
                    style={inputStyle}
                />
            ),
        },
        {
            Header: "Entry",
            accessor: "entry",
            width: columnWidths.entry,
            minWidth: 50,
            canResize: true,
            Cell: ({ row, value }) => (
                <input
                    type="text"
                    value={value}
                    onChange={(e) => handleEdit(row.index, "entry", e.target.value)}
                    style={inputStyle}
                />
            ),
        },
        {
            Header: "Exchange",
            accessor: "exchange",
            width: columnWidths.exchange,
            minWidth: 50,
            canResize: true,
        },
    ], [columnWidths, tickerListData]); // Add `columnWidths` to dependencies

    // Use the react-table hook
    const tableInstance = useTable(
        {
            columns,
            data: tickerListData,
            initialState: { sortBy },
            manualSortBy: true,
        },
        useSortBy,
        useResizeColumns,
        useFlexLayout
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy: currentSortBy },
    } = tableInstance;

    // Keep sort order after fetching data
    useEffect(() => {
        setSortBy(currentSortBy);
    }, [currentSortBy]);

    const handleColumnResize = (columnId, newWidth) => {
        // console.log(`Resizing ${columnId} to ${newWidth}px`); // Add this log to verify resize events
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [columnId]: newWidth, // Update the width of the resized column
        }));
    };

    return (
        <div className="ticker-list-view" style={{ width: "100%", height: "100%" }}>
            <div style={{ overflowX: "auto", height: "100%", display: "flex", flexDirection: "column" }}>
                <div {...getTableProps()}
                     style={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                    {/* Table header */}
                    {headerGroups.map(headerGroup => {

                        const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps(); // Extract key here

                        return (
                            <div
                                key={headerGroupKey}
                                {...headerGroupProps}
                                style={{ display: 'flex', flexDirection: 'row' }}
                            >
                                {headerGroup.headers.map((column, index) => {
                                    // console.log(column); // Check if canResize is set and props are correct
                                    const { key: columnKey, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps()); // Extract key

                                    return (
                                        <div
                                            key={columnKey}
                                            {...headerProps}
                                            style={{
                                                ...headerStyle,
                                                flexBasis: `${columnWidths[column.id]}px`, // Apply column width from state
                                                flexShrink: 0, // Prevent shrinking
                                                borderRight: '1px solid #eee',
                                                textAlign: 'left',
                                                position: 'relative',
                                            }}
                                        >
                                            {column.render('Header')}
                                            {column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
                                            {/* Resizer */}
                                            <div
                                                {...column.getResizerProps({
                                                    onMouseDown: (e) => {
                                                        const startWidth = columnWidths[column.id];
                                                        const startX = e.clientX;

                                                        const onMouseMove = (event) => {
                                                            const newWidth = Math.max(startWidth + event.clientX - startX, 50); // Ensure minimum width
                                                            handleColumnResize(column.id, newWidth); // Update state with new width
                                                        };

                                                        const onMouseUp = () => {
                                                            document.removeEventListener('mousemove', onMouseMove);
                                                            document.removeEventListener('mouseup', onMouseUp);
                                                        };

                                                        document.addEventListener('mousemove', onMouseMove);
                                                        document.addEventListener('mouseup', onMouseUp);
                                                    }
                                                })}
                                                style={resizerStyle} // Apply resizer styling
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}

                    {/* Table body */}
                    <div {...getTableBodyProps()}
                         style={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto'}}
                    >
                        {rows.map(row => {
                            prepareRow(row);
                            const isRowHighlighted = () => {
                                const [currentSymbol, currentExchange] = currentTicker;
                                return row.original.symbolLong === currentSymbol && row.original.exchange === currentExchange;
                            };
                            const {key, ...rowProps} = row.getRowProps();

                            return (
                                <div
                                    key={key}
                                    {...rowProps}
                                    onClick={() => {
                                        console.log("currentTicker", currentTicker);
                                        setCurrentTicker([row.original.symbolLong, row.original.exchange]);
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0px 0',
                                        alignItems: 'center',
                                        transition: "background-color 0.2s",
                                        backgroundColor: isRowHighlighted() ? '#d4f0ff' : 'white',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = isRowHighlighted() ? "#d4f0ff" : "#f2f6ff"}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = isRowHighlighted() ? "#85d5ff" : 'white'}
                                >
                                    {row.cells.map((cell, index) => {
                                        const { key: cellKey, ...cellProps } = cell.getCellProps();

                                        return (
                                            <div
                                                key={cellKey} // Explicitly pass the key here
                                                {...cellProps} // Spread the rest of the props
                                                style={{
                                                    ...getDynamicCellStyle(index, row.cells.length),
                                                    flexBasis: `${columnWidths[cell.column.id]}px`, // Apply column width from state
                                                    flexShrink: 0, // Prevent shrinking
                                                    textAlign: 'left',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const columnWidths = [2, 1.2, 0.8, 0.8, 1.2];

// Basic cell and input styling
const getDynamicCellStyle = (index, totalCells) => ({
    padding: "7px 12px",
    fontSize: "12px",
    flexBasis: 'auto', // Automatically size based on content
    flexGrow: 1,
    flexShrink: 0, // Prevent shrinking
    borderRight: '1px solid #eee',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const inputStyle = {
    width: "100%",
    padding: "0px",
    fontSize: "12px",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    textAlign: "left",
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const headerStyle = {
    padding: "7px 12px",
    marginTop: "1px",
    borderBottom: "1px solid #eee",
    borderRight: "1px solid #eee",
    fontSize: "12px",
    cursor: "pointer",
    position: "relative",
    flex: 1,
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const resizerStyle = {
    display: "inline-block",
    width: "4px",
    height: "100%",
    backgroundColor: "#eee",
    cursor: "col-resize",
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translateX(70%)",
}

export default TickerListView;
