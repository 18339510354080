import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './Chart.css';
import { HexAlphaColorPicker } from 'react-colorful';
import { intervalInMillis, debounce, formatPrice } from '../utils.js'; // Adjust the path according to your project structure


/**
 * Chart Component
 * TODO [UX] ⭐ Add mouse crosshair
 * TODO [FEAT] ⭐ Auto switch between timeframes depending on number of data points
 * TODO [FEAT] ⭐ Show signals on the chart
 * TODO [FEAT] ⭐️ Pair trading : charts for multiple coins. Consider coins with shorter history
 * TODO [UX] Add loading ... for changing timeframes
 * TODO [UX] ⭐️ Add save chart functionality(tickers, line colors, chart height)
 * TODO [FEAT] Pair trading : bring in portfolio data with API
 * TODO [FEAT] Pair trading : bring in portfolio data from Hyperliquid
 * TODO [FEAT] Pair trading : show how long the portfolio is
 * TODO [FEAT] Pair trading : show best combination of coins
 * TODO [BUG] Still load screen if backend is down
 * TODO [BUG] Crashes on loading BTC spot
 *
 * TODO [FEAT] y-axis log scale
 * TODO [FEAT] y-axis percentage scale
 * TODO [FEAT] Zoom y-axis
 * TODO [FEAT] Zoom/pan x-axis
 * TODO [BACK] Update live data
 * TODO [FEAT] Add secondary charts for indicators
 * TODO [FEAT] Add secondary chart on the right side
 * TODO [FEAT] Crash softly for missing chart errors
 * TODO [FEAT] Candlestick charts
 * TODO [FEAT] Unlocks on charts
 *
 * @param {string[]} currentTicker - An array of strings representing the current selected tickers. Each element in the array corresponds to a different line on the chart.
 * @param {Array<Array<{date: Date, price: number}>>} data - A 2D array where each sub-array contains objects representing the data points for a specific ticker.
 * @param {string[]} titles - An array of strings where each string is the title for a corresponding ticker. This usually includes the ticker name, timeframe, exchange, and current price.
 * @param {string[]} tickers - An array of available tickers that can be selected by the user. Each element includes both the ticker symbol and the exchange.
 * @param {Function} setTitles - A React state hook function to update the titles array.
 * @param {Function} fetchTickerData - A function to fetch new data for a specific ticker.
 * @param {Function} setCurrentTicker - A React state hook function to update the current selected tickers.
 * @param {Function} timeframe - A React state hook to store the current timeframe for the chart.
 * @param {Function} setTimeframe - A React state hook function to update the timeframe.
 * @param {Function} updaeData - A function to fetch new data for the selected tickers and timeframe.
 */
const Chart = ({
                   currentTicker,
                   data,
                   titles,
                   tickers,
                   setTitles,
                   fetchTickerData,
                   setCurrentTicker,
                   timeframe,
                   setTimeframe,
                   updateData,
                   fetchFlagRef,
                   handleDateRange }) => {
    const chartContainerRef = useRef(null); // Reference to the chart container
    const chartRef = useRef(null); // Reference to the SVG element
    const xScaleRef = useRef(null);
    const xScaleCopyedRef = useRef(null);
    const yScalesRef = useRef({});
    const xAxisRef = useRef(null);
    const yAxisLeftRef = useRef(null);
    const yAxisRightRef = useRef(null);
    const xScaleLinearRef = useRef(null);
    const yLeftScaleUpdateRef = useRef(false);
    const yRightScaleUpdateRef = useRef(false);
    const localFetchFlagRef = useRef(false);
    const kStateRef = useRef(0);

    const colorPickerRef = useRef();  // Reference to the color picker element
    const selectBoxRef = useRef(null);
    const xscaleDomainRef = useRef(null);
    const newYRightScaleRef = useRef(null);
    const newYLeftScaleRef = useRef(null);
    const currentContainerWidthRef = useRef(null);
    const [transform, setTransform] = useState(d3.zoomIdentity.translate(0, 0)); // Initial transform state
    const transformRef = useRef(d3.zoomIdentity.translate(0, 0));

    const [showSelectBox, setShowSelectBox] = useState(false);
    const [actionType, setActionType] = useState(null); // integer or 'add'
    const [searchTerm, setSearchTerm] = useState(''); // Select box search term
    const [filteredTickers, setFilteredTickers] = useState(tickers); // Select box list

    const timeframes = ['1h', '4h', '1d', '1w']; // List of timeframes

    const [color, setColor] = useState("#1f77b4");  // Default color
    const [showColorPicker, setShowColorPicker] = useState(false); // Show color picker
    const [selectedLineIndex, setSelectedLineIndex] = useState(null); // Index of the selected line for color change
    const [colorPickerPosition, setColorPickerPosition] = useState({ left: '50%', top: '50%' });

    const [initialTimeframeFlag, setInitialTimeframeFlag] = useState(true);

    let lastFetchTime = 0;
    const fetchThrottleInterval = 1000; // 1 second throttle
    const isInitialLoadRef = useRef(true);
    const resizeCallRef = useRef(false);

    // Helper function to get visible data based on the current X scale
    const getVisibleData = (xScale) => {
        return {
            L: data.filter((_, index) => titles[index].includes(';L')).map(lineData =>
                lineData.filter(d => xScale.domain()[0] <= d.date && d.date <= xScale.domain()[1])
            ),
            R: data.filter((_, index) => titles[index].includes(';R')).map(lineData =>
                lineData.filter(d => xScale.domain()[0] <= d.date && d.date <= xScale.domain()[1])
            )
        };
    };

    // Helper function to get Y min and max for left and right axes
    const getYMinMax = (visibleData) => {
        return {
            L: {
                min: d3.min(visibleData.L, d => d3.min(d, dd => dd.price)),
                max: d3.max(visibleData.L, d => d3.max(d, dd => dd.price))
            },
            R: {
                min: d3.min(visibleData.R, d => d3.min(d, dd => dd.price)),
                max: d3.max(visibleData.R, d => d3.max(d, dd => dd.price))
            }
        };
    };

    const updateZeroLine = (axis, scale, width, side) => {
        const zeroLine = d3.select(`g`).select(`.zero-line-${side}`);
        const yPos = scale(0);

        if (zeroLine.empty()) {
            // Create the zero line if it doesn't exist
            d3.select('g').append('line')
                .attr('class', `zero-line zero-line-${side}`)
                .attr('x1', 0)
                .attr('y1', yPos)
                .attr('x2', width)
                .attr('y2', yPos)
                .attr('stroke', 'rgba(186,186,186,0.50)')
                .attr('stroke-width', 0.5)
                .attr('clip-path', 'url(#clip)');
        } else {
            // Move the existing zero line
            zeroLine
                .attr('y1', yPos)
                .attr('y2', yPos)
                .attr('x2', width);  // Update width
        }
    };

    // Function to update axes and lines (reuse across renders)
    const updateAxesAndLines = (newX, width) => {
        // console.log('🔍 updateAxesAndLines');

        const yScales = yScalesRef.current;
        const visibleData = getVisibleData(newX);
        const yMinMax = getYMinMax(visibleData);
        // Update Y scales for left and right axes based on visible data
        if (yLeftScaleUpdateRef.current == false) {
            yScales.L.domain([yMinMax.L.min, yMinMax.L.max + (yMinMax.L.max - yMinMax.L.min) * 0.06]).nice();
            yScales.L1.domain([yMinMax.L.min, yMinMax.L.max + (yMinMax.L.max - yMinMax.L.min) * 0.06]).nice();
            // Update Y axes
            yAxisLeftRef.current.call(d3.axisLeft(yScales.L));
        }
        if (yRightScaleUpdateRef.current == false) {
            yScales.R.domain([yMinMax.R.min, yMinMax.R.max + (yMinMax.R.max - yMinMax.R.min) * 0.06]).nice();
            yScales.R1.domain([yMinMax.R.min, yMinMax.R.max + (yMinMax.R.max - yMinMax.R.min) * 0.06]).nice();
            // Update Y axes
            yAxisRightRef.current.call(d3.axisRight(yScales.R));
        }

        // Update the X axis
        xAxisRef.current.call(d3.axisBottom(newX));

        // Remove any existing zero lines (to avoid duplicating lines)
        d3.select('g').selectAll('.zero-line').remove(); // Select the `g` group

        // Update or create zero line for left and right Y-axes
        if (yMinMax.L.min < 0 && yMinMax.L.max > 0) updateZeroLine('L', yScales.L, width, 'left');
        if (yMinMax.R.min < 0 && yMinMax.R.max > 0) updateZeroLine('R', yScales.R, width, 'right');

        // Update the lines based on the new X and Y scales
        data.forEach((lineData, index) => {
            const axisSide = titles[index].split(';')[1].includes('L') ? 'L' : 'R';
            const y = yScales[axisSide]; // Choose the correct Y axis
            d3.select(`.line-${index}`)
                .attr('d', d3.line()
                    .x(d => newX(d.date))
                    .y(d => y(d.price))
                );

            // Last price line
            const currentPrice = lineData[lineData.length - 1].price;
            d3.select(`.current-price-line-${index}`)
                .attr('x1', 0)
                .attr('y1', y(currentPrice))
                .attr('x2', xAxisRef.current.node().getBBox().width)
                .attr('y2', y(currentPrice));

            // Update the filled box and text for the current price
            const boxY = y(currentPrice) - 8;

            d3.select(`.current-price-box-${index}`)
                .attr('y', boxY);

            d3.select(`.current-price-text-${index}`)
                .attr('y', boxY + 9)
                .text(formatPrice(currentPrice));
        });
    };

    const updateDrawing = () => {
        console.log('🔍 updateDrawing');
        const svgElement = d3.select(chartRef.current);
        let zoomState = d3.zoomTransform(svgElement.node());
        // console.log(d3.zoomTransform(svgElement.node()))
        const newX = zoomState.rescaleX(xScaleCopyedRef.current);
        const yScales = yScalesRef.current;
        d3.select('g').selectAll('.zero-line').remove(); // Select the `g` group

        updateZeroLine('L', newYLeftScaleRef.current, currentContainerWidthRef.current, 'left');
        updateZeroLine('R', newYRightScaleRef.current, currentContainerWidthRef.current, 'right');
        // Update the lines based on the new X and Y scales
        data.forEach((lineData, index) => {
            const axisSide = titles[index].split(';')[1].includes('L') ? 'L' : 'R';
            let y = yScales[axisSide]; // Choose the correct Y axis
            if (newYLeftScaleRef.current) {
                y = axisSide == 'R' ? newYRightScaleRef.current : newYLeftScaleRef.current;
            }
            // Choose the correct Y axis
            d3.select(`.line-${index}`)
                .attr('d', d3.line()
                    .x(d => newX(d.date))
                    .y(d => y(d.price))
                );
            // Last price line
            const currentPrice = lineData[lineData.length - 1].price;
            d3.select(`.current-price-line-${index}`)
                .attr('x1', 0)
                .attr('y1', y(currentPrice))
                .attr('x2', xAxisRef.current.node().getBBox().width)
                .attr('y2', y(currentPrice));

            // Update the filled box and text for the current price
            const boxY = y(currentPrice) - 8;

            d3.select(`.current-price-box-${index}`)
                .attr('y', boxY);

            d3.select(`.current-price-text-${index}`)
                .attr('y', boxY + 9)
                .text(formatPrice(currentPrice));
        });
    };

    // Reset zoom on double-click
    function resetZoomRightAxis() {
        console.log("Resetting zoom...");
        const svgElement = d3.select(chartRef.current).select(".Rightrect");
        // Apply the identity transform to reset zoom
        svgElement.transition().duration(750).call(zoomYRight.transform, d3.zoomIdentity);
        // // Reset the y-axis to the original scale
        // Update the y-axis to reflect the reset scale
        yAxisRightRef.current.call(d3.axisRight(newYRightScaleRef.current));
        // Update the chart elements
        updateDrawing();
        setTimeout(() => {
            yRightScaleUpdateRef.current = false;
        }, 1000);
    }

    // Reset zoom on double-click
    function resetZoomLeftAxis() {
        console.log("Resetting zoom...");
        const svgElement = d3.select(chartRef.current).select(".Leftrect");
        // Apply the identity transform to reset zoom
        svgElement.transition().duration(750).call(zoomYLeft.transform, d3.zoomIdentity);
        // // Reset the y-axis to the original scale
        // Update the y-axis to reflect the reset scale
        yAxisLeftRef.current.call(d3.axisLeft(newYLeftScaleRef.current));

        // Update the chart elements
        updateDrawing();
        setTimeout(() => {
            yLeftScaleUpdateRef.current = false;
        }, 1000);
    }

    // Create zoom behavior that only affects the y-axis
    const zoomYRight = d3.zoom()
        // .scaleExtent([0.3, 100])

        .on("zoom", zoomedYRight)
        .on("end", () => {
            yScalesRef.current.R = newYRightScaleRef.current;
        });

    function zoomedYRight(event) {
        // Rescale only the y-axis
        newYRightScaleRef.current = event.transform.rescaleY(yScalesRef.current.R1);
        yRightScaleUpdateRef.current = true;
        // Update the y-axis
        yAxisRightRef.current.call(d3.axisRight(newYRightScaleRef.current));
        updateDrawing();
    }

    // Create zoom behavior that only affects the y-axis
    const zoomYLeft = d3.zoom()
        //  .scaleExtent([0.5, 5])
        .on("zoom", (event) => {
            // Rescale only the y-axis
            newYLeftScaleRef.current = event.transform.rescaleY(yScalesRef.current.L1);
            yLeftScaleUpdateRef.current = true;
            // Update the y-axis
            yAxisLeftRef.current.call(d3.axisLeft(newYLeftScaleRef.current));
            updateDrawing();
        })
        .on("end", () => {
            yScalesRef.current.L = newYLeftScaleRef.current;
        });


    //// 📈 Render Chart ////
    const renderChart = () => {
        // console.log('📈 Rendering chart...');
        if (!data || data.length === 0) {
            console.log('❗️📈 No data to render');
            return;
        }
        const svgElement = d3.select(chartRef.current);
        const container = chartContainerRef.current;

        // Ensure the container exists
        if (!container) {
            console.log('❗📈️Container element is not available yet');
            return;
        }
        const containerWidth = container.offsetWidth;
        let containerHeight = container.offsetHeight;

        // Clear any existing SVG elements
        svgElement.selectAll('*').remove();

        const hasLeftAxis = titles.some(title => title.split(';')[1].includes('L'));
        const hasRightAxis = titles.some(title => title.split(';')[1].includes('R'));

        const margin = {
            top: 12,
            right: hasRightAxis ? 52 : 20,
            bottom: 35,
            left: hasLeftAxis ? 52 : 20
        };

        const width = containerWidth - margin.left - margin.right;
        currentContainerWidthRef.current = width;
        const height = containerHeight - margin.top - margin.bottom - 16; // reduce time frame selector height

        const svg = svgElement
            .attr('width', containerWidth)
            .attr('height', containerHeight)
            .attr("class", "chartSvg")
            // .style('background-color', '#dcdcdc') // Light grey background for testing
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .style('cursor', 'crosshair');  // Set crosshair cursor for the chart area

        // TEST Add a background rectangle to highlight the SVG area
        svg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .attr('fill', '#ffffff')

        // Function to add days
        function addDays(date, days) {
            let result = new Date(date);
            result.setTime(result.getTime() + days);
            return result;
        }

        //// 📐 AXES  ////
        const xScale = d3.scaleTime()
            .domain([
                d3.min(data, d => d3.min(d, dd => dd.date)),
                d3.max(data, d => d3.max(d, dd => dd.date))
            ])
            .range([0, width]);

        ///// update xscale domain //////
        let numberOfDaysIn60px = xScale.invert(60)
        // Subtracting dates
        let diffInMs = numberOfDaysIn60px.getTime() - d3.min(data, d => d3.min(d, dd => dd.date)).getTime(); // Difference in milliseconds
        // if (!resizeCallRef.current) {

        // } else {
        //     xScale.domain([
        //         d3.min(data, d => d3.min(d, dd => dd.date)),
        //         d3.max(data, d => d3.max(d, dd => dd.date))
        //     ])
        // }
        if (xscaleDomainRef.current != null) {
            xScale.domain([
                d3.min(data, d => d3.min(d, dd => dd.date)),
                xscaleDomainRef.current[1]
            ])
        } else {
            xScale.domain([
                d3.min(data, d => d3.min(d, dd => dd.date)),
                addDays(d3.max(data, d => d3.max(d, dd => dd.date)), diffInMs)
            ])
        }
        console.log(xscaleDomainRef.current)
        console.log(xScale.domain())
        // xscaleDomainRef.current = xScale.domain()

        xScaleLinearRef.current = d3.scaleTime().domain(xScale.domain())

        const copyXScale = xScale.copy();
        xScaleCopyedRef.current = copyXScale;

        if (transformRef.current) {
            // If it's the initial load, modify the transform by adding -40 to the x translation
            if (isInitialLoadRef.current) {
                console.log(' ⌙ transform xScale INITIAL LOAD', transformRef.current);
                transformRef.current = transformRef.current.translate(0, 0);
                isInitialLoadRef.current = false;
            } else {
                // transformRef.current = transformRef.current.translate(+60, 0);
            }
            // console.log(' ⌙ chart render transform xScale', transformRef.current);
            // update svg if new data added
            // Update the zoom transform based on modified values
            if (resizeCallRef.current) {
                xScaleLinearRef.current.range([0, width * transformRef.current.k])
                transformRef.current.x = -xScaleLinearRef.current(xscaleDomainRef.current[0]);
                const newX = transformRef.current.rescaleX(xScale);
                xScale.domain(newX.domain());

            } else {
                const newX = transformRef.current.rescaleX(xScale);
                xScale.domain(newX.domain());
            }

        }

        // Use helper functions to get visible data and yMinMax
        const visibleData = getVisibleData(xScale);
        let yMinMax = getYMinMax(visibleData);
        // if(yRightScaleUpdateRef.current){
        //     yMinMax.R.min=newYRightScaleRef.current?.domain()[0];
        //     yMinMax.R.max=newYRightScaleRef.current?.domain()[1];
        // }

        // Define y scales for left and right axes
        const yScales = {
            L: d3.scaleLinear()
                .domain([yMinMax.L.min, yMinMax.L.max + (yMinMax.L.max - yMinMax.L.min) * 0.06])
                .nice()
                .range([height, 0]),
            L1: d3.scaleLinear()
                .domain([yMinMax.L.min, yMinMax.L.max + (yMinMax.L.max - yMinMax.L.min) * 0.06])
                .nice()
                .range([height, 0]),


            R: d3.scaleLinear()
                .domain([yMinMax.R.min, yMinMax.R.max + (yMinMax.R.max - yMinMax.R.min) * 0.06])
                .nice()
                .range([height, 0]),
            R1: d3.scaleLinear()
                .domain([yMinMax.R.min, yMinMax.R.max + (yMinMax.R.max - yMinMax.R.min) * 0.06])
                .nice()
                .range([height, 0])
        };
        if (yRightScaleUpdateRef.current) {
            yScales.R.domain(newYRightScaleRef.current?.domain())
            if (isNaN(newYLeftScaleRef.current?.domain()[0]) == false)
                yScales.L.domain(newYLeftScaleRef.current?.domain())
        }
        if (yLeftScaleUpdateRef.current) {
            if (isNaN(newYLeftScaleRef.current?.domain()[0]) == false)
                yScales.L.domain(newYLeftScaleRef.current?.domain())
        }

        // Store scales in refs to access them in zoom handler
        xScaleRef.current = xScale;
        yScalesRef.current = yScales;
        newYLeftScaleRef.current = yScales.L;
        newYRightScaleRef.current = yScales.R;

        const xAxis = svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScale));
        xAxisRef.current = xAxis;

        const yAxisLeft = svg.append('g')
            .attr('transform', `translate(0,0)`)
            .call(d3.axisLeft(yScales.L));
        yAxisLeftRef.current = yAxisLeft;

        const yAxisRight = svg.append('g')
            .attr('transform', `translate(${width},0)`)
            .call(d3.axisRight(yScales.R));
        yAxisRightRef.current = yAxisRight;

        if (zoomYRight) {
            // Overlay on the y-axis for zoom
            svg.append("rect")
                .attr("class", "Rightrect")
                .attr("x", width)  // Slightly wider than the y-axis to detect mouseover
                .attr("y", 0)
                .attr("width", 50)  // Width of the y-axis zoom area
                .attr("height", height)
                .attr("fill", "transparent")  // Transparent but still captures mouse events
                .style("cursor", "ns-resize")
                .call(zoomYRight).on("dblclick.zoom", null)
                .on("dblclick", resetZoomRightAxis);  // Listen for double-click

        }

        if (zoomYLeft && hasLeftAxis) {
            // Overlay on the y-axis for zoom
            svg.append("rect")
                .attr("class", "Leftrect")
                .attr("x", -margin.left)  // Slightly wider than the y-axis to detect mouseover
                .attr("y", 0)
                .attr("width", 50)  // Width of the y-axis zoom area
                .attr("height", height)
                .attr("fill", "transparent")  // Transparent but still captures mouse events
                .style("cursor", "ns-resize")
                .call(zoomYLeft).on("dblclick.zoom", null)
                .on("dblclick", resetZoomLeftAxis);  // Listen for double-click
        }

        // Add or update the zero line for the left Y axis if applicable
        if (yMinMax.L.min < 0 && yMinMax.L.max > 0) {
            svg.append('line')  // Select the `g` group to append the line within it
                .attr('class', 'zero-line')  // Add a class for easy selection and removal
                .attr('x1', 0)
                .attr('y1', yScales.L(0))
                .attr('x2', xAxisRef.current.node().getBBox().width)
                .attr('y2', yScales.L(0))
                .attr('stroke', 'rgba(186,186,186,0.50)')
                .attr('stroke-width', 0.5)
        }

        // Add or update the zero line for the right Y axis if applicable
        if (yMinMax.R.min < 0 && yMinMax.R.max > 0) {
            svg.append('line')  // Select the `g` group to append the line within it
                .attr('class', 'zero-line')  // Add a class for easy selection and removal
                .attr('x1', 0)
                .attr('y1', yScales.R(0))
                .attr('x2', xAxisRef.current.node().getBBox().width)
                .attr('y2', yScales.R(0))
                .attr('stroke', 'rgba(186,186,186,0.50)')
                .attr('stroke-width', 0.5)
                .attr('clip-path', 'url(#clip)');

        }

        // Define a clipping path to constrain the line within the axes
        svg.append('defs')
            .append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('width', width)
            .attr('height', height)

        //// 📈LINE ////
        // Add lines for each ticker
        data.forEach((lineData, index) => {
            const axisSide = titles[index].split(';')[1]
            const y = yScales[axisSide];
            let lineColor = '';
            // Check if line has a color
            if (!!titles[index].split(';')[2]) {
                lineColor = titles[index].split(';')[2]
            } else {
                // If no color then assign a default color
                lineColor = d3.schemeCategory10[index % 10];
                // Add line color to titles
                titles[index] = titles[index] + ';' + lineColor;
            }

            svg.append('path')
                .datum(lineData)
                .attr('class', `line-path line-${index}`) // Unique class for each line
                .attr('clip-path', 'url(#clip)')
                .attr('transform', 'translate(0, 0)')
                .attr('d', d3.line()
                    .x(d => xScale(d.date))
                    .y(d => y(d.price))
                )
                .style('stroke', lineColor);

            // Extract the last price for the current price
            const currentPrice = lineData[lineData.length - 1].price;

            // Add a dotted line for the current price
            svg.append('line')
                .attr('class', `current-price-line-${index}`)
                .attr('x1', 0)
                .attr('y1', y(currentPrice))
                .attr('x2', width)
                .attr('y2', y(currentPrice))
                .attr('stroke', lineColor)
                .attr('stroke-width', 1)
                .attr('stroke-dasharray', '1 2')
                .attr('clip-path', 'url(#clip)');

            // Add a filled box for the current price at the x-axis
            const boxWidth = margin.right;
            const boxHeight = 16;
            let boxX = width + 1;
            const boxY = y(currentPrice) - boxHeight / 2;
            let textAnchor = 'start';
            let boxXText = boxX + 7;

            if (axisSide === 'L') {
                boxX = 0 - boxWidth;
                textAnchor = 'end';
                boxXText = boxX + boxWidth - 7;
            }

            // Add the filled box (background)
            svg.append('rect')
                .attr('x', boxX)
                .attr('y', boxY)
                .attr('width', boxWidth)
                .attr('height', boxHeight)
                .attr('fill', lineColor)
                .attr('class', `current-price-box-${index}`);

            // Add the text (current price) inside the filled box
            svg.append('text')
                .attr('x', boxXText)
                .attr('y', boxY + boxHeight / 2 + 1)
                .attr('fill', '#fff')
                .attr('text-anchor', textAnchor)
                .attr('alignment-baseline', 'middle')
                .attr('class', `current-price-text-${index}`)
                .style('font-size', 10)
                .text(formatPrice(currentPrice));
        });

        //// ⚔️ CROSSHAIR ////
        // Create group for crosshair elements
        const crosshairGroup = svg.append('g')
            .attr('class', 'crosshair-group')
            .style('display', 'none');  // Initially hidden

        // Create group for crosshair lines and apply clip-path
        const crosshairLinesGroup = crosshairGroup.append('g')
            .attr('class', 'crosshair-lines')
            .attr('clip-path', 'url(#clip)');  // Only crosshair lines are clipped

        // Add vertical and horizontal crosshair lines
        crosshairLinesGroup.append('line')
            .attr('class', 'vertical-line')
            .attr('y1', 0)
            .attr('y2', height)
            .attr('stroke', 'rgba(0, 0, 0, 0.8)')
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', '6,6');

        crosshairLinesGroup.append('line')
            .attr('class', 'horizontal-line')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('stroke', 'rgba(0, 0, 0, 0.8)')
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', '6,6');

        // Create group for price boxes and date box (not clipped)
        const crosshairLabelsGroup = crosshairGroup.append('g')
            .attr('class', 'crosshair-labels');

        // Define box dimensions
        let boxWidth = 50;  // Adjust as needed
        const boxHeight = 16;

        // Add a rectangle and text for the date display (at the bottom of the chart)
        const dateBoxHeight = 16;
        const dateBox = crosshairLabelsGroup.append('g')
            .attr('class', 'date-box');

        dateBox.append('rect')
            .attr('x', 0)
            .attr('y', height)
            .attr('height', dateBoxHeight)
            .attr('fill', 'rgba(0, 0, 0, 0.7)');

        dateBox.append('text')
            .attr('x', 0)
            .attr('y', height + dateBoxHeight / 2)
            .attr('fill', '#fff')
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .style('font-size', 10);

        // Create price boxes and texts for each axis side present
        ['L', 'R'].forEach(axisSide => {
            // Check if this axis side is present in the data
            const linesOnAxis = data.filter((_, index) => titles[index].split(';')[1] === axisSide);
            if (linesOnAxis.length > 0) {
                // For example, pick the color of the first line on this axis
                const firstLineIndex = data.findIndex((_, index) => titles[index].split(';')[1] === axisSide);
                const lineColor = titles[firstLineIndex].split(';')[2] || '#000';

                const priceGroup = crosshairLabelsGroup.append('g')
                    .attr('class', `price-group-${axisSide}`);

                // Add the price box
                priceGroup.append('rect')
                    .attr('class', `price-box-${axisSide}`)
                    .attr('width', boxWidth)
                    .attr('height', boxHeight)
                    .attr('fill', lineColor);

                // Add the price text
                priceGroup.append('text')
                    .attr('class', `price-text-${axisSide}`)
                    .attr('fill', '#fff')
                    .attr('alignment-baseline', 'middle')
                    .style('font-size', 10);
            }
        });

        // Mouse event listeners for crosshair functionality
        svg.on('mousemove', function(event) {
            const [mouseX, mouseY] = d3.pointer(event, this);
            const date = xScale.invert(mouseX); // Get the date for the mouse position

            // Format date as '28 Sept '24 01:00'
            const formattedDate = d3.timeFormat('%d %b \'%y %H:%M')(date);

            // Update the vertical crosshair line to follow the mouse's X position
            crosshairLinesGroup.select('.vertical-line')
                .attr('x1', mouseX)
                .attr('x2', mouseX);

            // Update the horizontal crosshair line to follow the mouse's Y position
            crosshairLinesGroup.select('.horizontal-line')
                .attr('y1', mouseY)
                .attr('y2', mouseY);

            // Update the date box
            const dateTextWidth = formattedDate.length * 6; // Approximate character width
            const dateBoxWidth = dateTextWidth + 10; // Add padding

            crosshairLabelsGroup.select('.date-box rect')
                .attr('x', mouseX - dateBoxWidth / 2)
                .attr('width', dateBoxWidth);

            crosshairLabelsGroup.select('.date-box text')
                .attr('x', mouseX)
                .text(formattedDate);

            // Now, for each axis side present, calculate and display the price
            ['L', 'R'].forEach(axisSide => {
                // Check if this axis side is present in the data
                const linesOnAxis = data.filter((_, index) => titles[index].split(';')[1] === axisSide);
                if (linesOnAxis.length > 0) {
                    // Get the corresponding y-scale
                    const yScale = yScales[axisSide];
                    // Invert the mouseY to get the price
                    const price = yScale.invert(mouseY);

                    // Display price near the corresponding axis (left or right)
                    let priceBoxX, priceTextX, textAnchor;
                    if (axisSide === 'L') {
                        // Left axis
                        priceBoxX = -boxWidth + 1;  // Adjusted to align with the left axis
                        priceTextX = priceBoxX + boxWidth / 2;
                        textAnchor = 'middle';
                    } else {
                        // Right axis
                        priceBoxX = width - 1;  // Adjusted to align within the chart area
                        priceTextX = priceBoxX + boxWidth / 2;
                        textAnchor = 'middle';
                    }

                    // Update the position and text of the price box and text
                    const priceGroup = crosshairLabelsGroup.select(`.price-group-${axisSide}`);

                    priceGroup.select(`.price-box-${axisSide}`)
                        .attr('x', priceBoxX)
                        .attr('y', mouseY - boxHeight / 2);

                    priceGroup.select(`.price-text-${axisSide}`)
                        .attr('x', priceTextX)
                        .attr('y', mouseY)
                        .attr('text-anchor', textAnchor)
                        .text(formatPrice(price));
                }
            });

            // Show the crosshair group
            const isMouseInChart = mouseX >= -1 && mouseX <= width + 1 && mouseY >= -1 && mouseY <= height + 1;
            crosshairGroup.style('display', isMouseInChart ? 'block' : 'none');
        })


        //// CHART TITLE ////
        // Add mouseover effect to a group element
        const addMouseOverEffect = (group, rect) => {
            group.on('mouseover', () => {
                rect.style('stroke', '#737373')
                    .style('stroke-width', 1)
                    .style('fill', 'rgba(0,51,255,0.06)');
            }).on('mouseout', () => {
                rect.style('stroke', 'none')
                    .style('fill', 'none');
            });
        };

        //// 📝TITLES ////
        // Iterate over the title array and create a title group for each ticker
        titles.forEach((title, index) => {
            // Create a group to contain both the text and the rectangle
            const titleGroup = svg.append('g')
                .attr('transform', `translate(0, ${index * 24})`) // Adjust vertical spacing between titles
                .style('cursor', 'pointer')

            // Add chart title
            const titleText = titleGroup.append('text')
                .attr('class', 'title-text')
                .attr('x', 11)
                .attr('y', 18)
                .text(title.split(';')[0]);

            // Measure the width of the title text
            const titleWidth = titleText.node().getBBox().width;

            // Add a semi-transparent rectangle behind the title text
            const titleBackground = titleGroup.insert('rect', 'text')
                .attr('class', 'title-background')
                .attr('x', 4)
                .attr('y', 3)
                .attr('width', titleWidth + 12)
                .attr('height', 20);

            // Add buttons for toggling axis and deleting the line
            const buttonGroup = titleGroup.append('g')
                .attr('transform', `translate(${titleWidth + 20}, 3)`); // Position buttons to the right of the title

            // Button to toggle axis (left/right)
            const axisToggleButton = buttonGroup.append('text')
                .attr('class', `axis-toggle-button`)
                .attr('class', `axis-toggle-button-${index}`)
                .attr('x', 0)
                .attr('y', 15)
                .style('font-size', '12px')
                .style('cursor', 'pointer')
                .style('display', 'none')
                .text('📐')
                .on('click', (event) => {
                    event.stopPropagation(); // Prevent click event from triggering the title's click handler
                    toggleAxis(index);
                });
            const isRight = titles[index].split(';')[1] === 'R';
            axisToggleButton.style('transform', isRight ? 'scaleX(-1) translateX(-1em)' : 'scaleX(1) translateX(0)');

            // Button to pick a color
            const colorPickerButton = buttonGroup.append('text')
                .attr('class', 'color-picker-button')
                .attr('x', 20)
                .attr('y', 15)
                .style('font-size', '12px')
                .style('cursor', 'pointer')
                .style('display', 'none')
                .text('🎨')  // Color picker emoji
                .on('click', (event) => {
                    event.stopPropagation(); // Prevent click event from triggering the title's click handler
                    openColorPicker(index, event);
                });

            // Button to delete the line
            const deleteButton = buttonGroup.append('text')
                .attr('class', 'delete-button')
                .attr('x', 40)
                .attr('y', 15)
                .style('font-size', '12px')
                .style('cursor', 'pointer')
                .style('display', 'none')
                .text('🗑️')
                .on('click', (event) => {
                    event.stopPropagation();
                    deleteLine(index);
                });

            // Add mouseover effect to the title group to show/hide buttons
            titleGroup.on('mouseover', () => {
                axisToggleButton.style('display', 'block');
                colorPickerButton.style('display', 'block');
                deleteButton.style('display', 'block');
                titleBackground
                    .attr('width', titleWidth + 75)
                    .style('stroke', '#737373')
                    .style('stroke-width', 1)
                    .style('fill', 'rgba(0,51,255,0.06)');
            }).on('mouseout', () => {
                axisToggleButton.style('display', 'none');
                colorPickerButton.style('display', 'none');
                deleteButton.style('display', 'none');
                titleBackground
                    .attr('width', titleWidth + 12)
                    .style('stroke', 'none')
                    .style('fill', 'rgba(184,184,184,0.15)');
            });

            // Open Select box for the specific ticker
            titleGroup.on('click', () => {
                setShowSelectBox(true);
                setActionType(index); // Set actionType to the index of the ticker being clicked
            });

        });

        //// 📐AXIS TOGGLING ////
        // Functions to handle axis toggling and line deletion
        function toggleAxis(index) {
            const newTitles = [...titles]; // Clone the array
            const axis = newTitles[index].split(';')[1];
            const isRight = axis === 'R';
            newTitles[index] = newTitles[index].replace(';' + axis, isRight ? ';L' : ';R');

            // Update the state to trigger a re-render
            setTitles(newTitles);

            const axisToggleButton = svg.select(`.axis-toggle-button-${index}`);
            axisToggleButton.style('transform', isRight ? 'scaleX(1) translateX(0)' : 'scaleX(-1) translateX(-1em)');
        }

        //// 🗑️DELETE LINE ////
        function deleteLine(index) {
            // Your logic to delete the line and its corresponding data and UI elements
            console.log(`Deleting line ${index}`);
            // Example: Remove the line from the data array and update the chart
            setCurrentTicker(prevTickers => prevTickers.filter((_, i) => i !== index));
        }

        //// ➕Plus Group ////
        const plusGroup = svg.append('g')
            .attr('transform', `translate(0, ${25 + ((titles.length - 1) * 24)})`)
            .style('cursor', 'pointer');

        const plusBackground = plusGroup.append('rect')
            .attr('class', 'plus-background')
            .attr('x', 4)
            .attr('y', 3)
            .attr('width', 20)
            .attr('height', 20);

        plusGroup.append('text')
            .attr('class', 'plus-group-text')
            .attr('x', 14)
            .attr('y', 16)
            .text('+');

        addMouseOverEffect(plusGroup, plusBackground);
        // Open Select box
        plusGroup.on('click', () => {
            setShowSelectBox(true);
            setActionType('add');
        });
    }

    //// 🎨COLOR PICKER ////
    const updateLineColor = (index, color) => {
        const newTitles = [...titles]; // Clone the titles array

        let lineColor = '';
        if (!!newTitles[index].split(';')[2]) {
            lineColor = newTitles[index].split(';')[2];
        } else {
            lineColor = d3.schemeCategory10[index % 10];
            newTitles[index] = newTitles[index] + ';' + lineColor;
        }

        if (lineColor !== color) {
            console.log('Updating color', lineColor, 'to', color);
            newTitles[index] = newTitles[index].replace(';' + lineColor, ';' + color);
            setTitles(newTitles); // Set the cloned array
        }
    };

    const openColorPicker = (index, event) => {
        console.log('Opening color picker for line', titles[index]);
        // Start cursor in current color
        setColor(titles[index].split(';')[2]);
        setSelectedLineIndex(index);
        setShowColorPicker(true);
        const pickerPosition = {
            left: `${event.clientX}px`,
            top: `${event.clientY}px`,
        };
        setColorPickerPosition(pickerPosition);
    };

    const handleColorPickerClose = () => {
        if (selectedLineIndex !== null) {
            updateLineColor(selectedLineIndex, color);
            setShowColorPicker(true);
        }
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current) {
            const path = event.composedPath();
            if (!path.includes(colorPickerRef.current)) {
                setShowColorPicker(false);
            }
        }
    };

    useEffect(() => {
        if (showColorPicker) {
            document.addEventListener('mousedown', handleClickOutside, true);
        } else {
            document.removeEventListener('mousedown', handleClickOutside, true);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [showColorPicker]);

    //// 🎹 SELECT BOX ////
    const handleOutsideClick = (event) => {
        if (
            selectBoxRef.current &&
            !event.target.closest('.select-box') &&
            !event.target.closest('.title-group') &&
            !event.target.closest('.plus-group')
        ) {
            setShowSelectBox(false);
        }
    };

    // Handle search input
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setFilteredTickers(tickers.filter(ticker =>
            ticker.toLowerCase().includes(event.target.value.toLowerCase())
        ));
    };

    // Handle ticker selection
    const handleTickerSelect = (index) => {
        if (actionType !== null && actionType !== 'add') {
            const updatedTickers = [...currentTicker];
            updatedTickers[actionType] = filteredTickers[index];
            setCurrentTicker(updatedTickers); // Setting current ticker has a hook to fetch data
        } else if (actionType === 'add') {
            handleDateRange(xScaleCopyedRef.current.domain())
            setCurrentTicker([...currentTicker, filteredTickers[index]]); // Setting current ticker has a hook to fetch data
        }
        setShowSelectBox(false);
    };

    //// ⏰ TIMEFRAME ////
    // Function to handle timeframe change
    const handleTimeframeChange = (newTimeframe) => {
        console.log('⏰ Changing timeframe to', newTimeframe);
        setInitialTimeframeFlag(false);

        // Update ticker titles with the new timeframe
        const updatedTitles = titles.map((title, index) => {
            // Split the title string into its parts
            const [symbol, timeframe, exchange, price_etc] = title.split(' · ');
            // Reconstruct the title with the new timeframe
            return `${symbol} · ${newTimeframe} · ${exchange} · ${price_etc}`;
        });

        // Update the active timeframe -> trigger 🪝to fetch new data
        setTimeframe(newTimeframe); // This will trigger a re-render with the new active timeframe
        // Update the titles state, triggering 🪝to update chart UX
        setTitles(updatedTitles);
    };

    // Function to fetch more data when the user pans to the left
    const fetchMoreData = debounce(async () => {
        const now = Date.now();
        if (now - lastFetchTime < fetchThrottleInterval) return; // Throttle fetches
        lastFetchTime = now;

        console.log('🚨 Fetching state', fetchFlagRef.current);
        if (fetchFlagRef.current) {
            console.log('🚨 Already fetching data...');
            return
        }  // Prevent multiple fetches
        fetchFlagRef.current = true;
        localFetchFlagRef.current = true;

        try {
            // Set the startTime to the earliest date in the existing data
            const earliestDate = d3.min(data, lineData => d3.min(lineData, d => d.date));
            const newEndTime = earliestDate.getTime(); // Convert to milliseconds
            const newStartTime = newEndTime - 700 * intervalInMillis(timeframe); // Fetch 700 points before the earliest

            console.log('🚨 Fetching previous 700 points... fetchFlagRef now', fetchFlagRef.current);
            if (xscaleDomainRef.current) {
                const newEndTime = xscaleDomainRef.current[0].getTime(); // Convert to milliseconds
                const newStartTime = newEndTime - 700 * intervalInMillis(timeframe); // Fetch 700 points before the earliest
                // Await the completion of updateData to ensure no further calls until it's done
                await updateData(tickers, newStartTime, newEndTime, "fetchMoreData");
            } else {
                // Await the completion of updateData to ensure no further calls until it's done
                await updateData(tickers, newStartTime, newEndTime, "fetchMoreData");
            }

        } catch (error) {
            console.error("🚨 Error fetching more data:", error);
        } finally {
            // fetchFlagRef.current = false;  // Reset fetching state after completion or error
        }
    }, 500); // Debounce with 500ms delay

    //// 🪝 CHART RENDER UPDATE ////
    useEffect(() => {
        let resizeObserver = null;

        console.log('🪝 data/titles changed triggered renderchart() zoom() and resize() START');
        // Initial renderChart on load
        const renderAndHandleResize = () => {
            if (data.length > 0) {

                renderChart();
                // console.log(' ⌙ transformRef.current', transformRef.current);
            }
        };

        renderAndHandleResize();
        document.addEventListener('mousedown', handleOutsideClick, true);

        // Define zoom behavior
        const svgElement = d3.select(chartRef.current);

        const zoom = d3.zoom()
            .scaleExtent([0.3, 500]) // Limit zoom in and out
            .translateExtent([[-1000, -Infinity], [2000, Infinity]]) // Adjust panning limits
            .on('zoom', (event) => {
                const sourceEvent = event.sourceEvent;

                if (!xScaleRef.current) {
                    console.log('zoom : xScaleRef is not initialized');
                    return;
                }

                let transformZoom = event.transform;
                let kUpdate = false;

                if (transformZoom.k != 1 && kStateRef.current != transformZoom.k) {
                    kUpdate = true;
                }

                resizeCallRef.current = true;
                console.log("fetchFlagRef.current", fetchFlagRef.current);
                console.log("localFetchFlagRef.current", localFetchFlagRef.current);

                const width = currentContainerWidthRef.current;
                // Ignore this block when Ctrl + wheel is pressed for normal zoom
                if (!sourceEvent?.altKey) {
                    if (kUpdate || sourceEvent?.type === "wheel") {
                        xScaleLinearRef.current.range([0, transformZoom.k * width]);
                        if (xscaleDomainRef.current == null) {
                            transformZoom.x = -(xScaleLinearRef.current(xScaleCopyedRef.current.domain()[1]) - width);
                        } else {
                            transformZoom.x = -(xScaleLinearRef.current(xscaleDomainRef.current[1]) - width);
                        }
                        kStateRef.current = transformZoom.k;
                    }
                }

                xScaleLinearRef.current.range([0, transformZoom.k * width]);
                transformRef.current = transformZoom; // Save the current transform state

                const newX = transformZoom.rescaleX(xScaleCopyedRef.current); // Use the stored xScale from ref
                console.log(newX.domain());
                xscaleDomainRef.current = newX.domain();

                // Update the axes and the lines based on the new transform
                updateAxesAndLines(newX, width);
            })
            .on('end', (event) => {
                if (!xScaleRef.current) {
                    console.log('zoom end : xScaleRef is not initialized');
                    return;
                }
                let transformZoom = transformRef.current;
                const newX = transformZoom.rescaleX(xScaleCopyedRef.current); // Use the stored xScale from ref

                // const newX = xScaleRef.current; // Rescale based on transform
                const earliestVisibleDate = newX.invert(0); // Detect if more data is needed
                const earliestLoadedDate = d3.min(data, lineData => d3.min(lineData, d => d.date));
                // console.log('earliestVisibleDate', earliestVisibleDate);
                // console.log('earliestLoadedDate', earliestLoadedDate);
                // console.log('test', earliestVisibleDate < earliestLoadedDate);
                if (earliestVisibleDate < earliestLoadedDate && !fetchFlagRef.current) {
                    fetchMoreData(); // Trigger fetchMoreData only once zooming/panning ends
                }
            });

        zoom.wheelDelta(function (event) {
            if((-event.deltaY * (event.deltaMode ? 120 : 1) / 2000)==0){
                const transform = d3.zoomTransform(svgElement.node());
                const zoomTransform = d3.zoomIdentity
                    .scale(transform.k)
                    .translate((transform.x-event.deltaX)/transform.k, transform.y);  // Only translate on the x-axis, y-axis remains unaffected

                // Apply the new zoom transform to the SVG or element
                svgElement.call(zoom.transform, zoomTransform);
            }
            return -event.deltaY * (event.deltaMode ? 120 : 1) / 2000; // Adjust the denominator to increase/decrease zoom speed
        });

        // Check if ResizeObserver is available
        if (typeof ResizeObserver !== 'undefined') {
            resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    if (entry.contentRect) {
                        // console.log('🔍 resize : 🚀 ResizeObserver triggered');
                        // If the container size changes, re-render the chart
                        renderChart();
                    }
                }
            });

            // Observe the chart container
            if (chartContainerRef.current) {
                resizeObserver.observe(chartContainerRef.current);
            }
        } else {
            console.warn(' ⌙ ResizeObserver is not supported in this browser.');
        }
        // If transform is set, apply it to the xScale
        if (localFetchFlagRef.current) {
            // calculate updated K
            let actualdomain = xScaleCopyedRef.current.domain();
            let lastDomainInView = xscaleDomainRef.current;

            const actualStart = actualdomain[0].getTime();  // Convert to milliseconds
            const actualEnd = actualdomain[1].getTime();
            const lastStart = lastDomainInView[0].getTime();
            const lastEnd = lastDomainInView[1].getTime();

            const k = (actualEnd - actualStart) / (lastEnd - lastStart);
            console.log("transformRef.current.x", xscaleDomainRef.current[0])

            kStateRef.current = k;
            const width = currentContainerWidthRef.current;
            const tx = -((lastStart - actualStart) / (actualEnd - actualStart)) * width;
            const zoomTransform = d3.zoomIdentity
                .scale(k)
                .translate(tx, transformRef.current.y);  // Only translate on the x-axis, y-axis remains unaffected

            // Apply the new zoom transform to the SVG or element
            svgElement.call(zoom.transform, zoomTransform);
            // svgElement.attr("transform", `translate(${transformRef.current.x}, ${transformRef.current.y}) scale(${k})`);
            // subtra
            localFetchFlagRef.current = false;
        }

        // Apply zoom behavior to the SVG once it's rendered
        if (svgElement) {
            console.log(' ⌙ zoom : 🚀 Applying zoom behavior');
            svgElement.call(zoom).on("dblclick.zoom", null);
            // Disable default Ctrl + Wheel scroll, but allow zooming
            if (!isInitialLoadRef.current) {
                // console.log(' ⌙ zoom : Not Initial load', transformRef.current);
            }
        }


        return () => {
            document.removeEventListener('mousedown', handleOutsideClick, true);
            // Clean up the observer when the component is unmounted
            if (resizeObserver && chartContainerRef.current) {
                // window.removeEventListener('resize', chartContainerRef.current);
                resizeObserver.unobserve(chartContainerRef.current);
            }
        }
    }, [data, titles]);

    // Use useEffect to trigger updateData when timeframe changes
    useEffect(() => {
        if (timeframe && !initialTimeframeFlag) {
            // Fetch new data for the updated timeframe
            console.log('🪝 timeframe changed triggered updateData')

            updateData(currentTicker, false, false, "timeframe");

        } else {
            console.log('🪝 timeframe Initiated(no updates)')
        }
    }, [timeframe]);

    return (
        <div className="chart-container" ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
            <div className="timeframe-selector">
                {timeframes.map((tf, index) => (
                    <div
                        key={tf}
                        className={`timeframe-option ${timeframe === tf ? 'active' : ''}`}
                        role={"button"}
                        tabIndex={index}
                        onClick={() => handleTimeframeChange(tf)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleTimeframeChange(tf);
                            }
                        }}

                    >
                        {tf}
                    </div>
                ))}
            </div>
            {showColorPicker && (
                <div
                    ref={colorPickerRef}
                    className="color-picker-container"
                    style={{
                        position: 'absolute',
                        left: colorPickerPosition.left,
                        top: colorPickerPosition.top,
                        transform: 'translate(+2%, -18%)',
                        zIndex: 1000,
                    }}
                    onMouseUp={handleColorPickerClose} // Handle color application on mouse up
                >
                    <HexAlphaColorPicker color={color} onChange={setColor} />
                </div>
            )}
            {/* <button id="leftBtn">Pan Left</button>
                        <button id="rightBtn">Pan Right</button>
                        <button id="resetBtn">Reset Pan</button> */}
            <svg ref={chartRef}></svg>
            {showSelectBox && (
                <div ref={selectBoxRef} className="select-box">

                    <h4 className="select-box-title">Select Ticker</h4>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="select-box-input"
                    />
                    <ul className="select-box-list">
                        {filteredTickers.map((ticker, index) => {
                            const [symbol, exchange] = ticker.split(', ');
                            const isRoundedItem = index >= 5;
                            const isLastItem = index === filteredTickers.length - 1;
                            return (
                                <li
                                    key={index}
                                    className={`select-box-item ${isRoundedItem ? 'select-box-item-rounded' : ''} ${isLastItem ? 'select-box-item-last' : ''}`}
                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                    onClick={() => handleTickerSelect(index)}
                                >
                                    <span className="ticker-symbol">{symbol}</span>
                                    <span className="ticker-exchange">{exchange}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

        </div>
    );
};

export default Chart;
